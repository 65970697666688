import React, { useEffect, useState } from "react"
import { useLocation } from '@reach/router'
import toast from "react-hot-toast"
import { navigate } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Loader from "../components/addon/Loader"

import PAYMENT_API from '../api/payment'
import DOCTOR_API from '../api/doctors'
import USER_API from '../api/user'
import EVENT_API from '../api/events'

const Thankyou = (props) => {

    const location = useLocation()
    const [loading, setLoading] = useState(false) 
    const [payDetails, setPayDetails] = useState(null)
    const [detailsType, setDetailsType] = useState(null)

    const goHome = () => {
        navigate('/')
        return
    }

    const getEventDetails = (id) => {
        setLoading(true)
        EVENT_API.getEventVoucherDetails(id)
        .then(res => {
            if(res?.event_title) {
                setLoading(false)
                setPayDetails(res)
                setDetailsType('EventDetails')
            } else {
                setDetailsType('NoDetailsFound')
                toast.error('No Details Found')
            }
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
            goHome()
        })
    }

    const getPaymentDetailsById = (id) => {
        setLoading(true)
        PAYMENT_API.getPaymentDetails(id)
        .then(res => {
            if(res && Object.keys(res).length) {
                if(res && Object.keys(res).length === 3) {
                    getUserDataUsingApptno(res?.ApptNo, res)
                    setDetailsType('CompleteForm')
                } else if (res?.payloadtype === 'IPD' || res?.payloadtype === 'OPD' || res?.payloadtype === 'RSV') {
                    setDetailsType(res?.payloadtype)
                    setPayDetails(res)
                    setLoading(false)
                } else {
                    setPayDetails(res)
                    setLoading(false)
                    setDetailsType('CompleteForm')
                }
            } else {
                setDetailsType('NoDetailsFound')
                toast.error('No Details Found')
            }
            setPayDetails(res)
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
            goHome()
        })
    }

    const getUserDataUsingApptno = (id, response) => {
        DOCTOR_API.getAppoinmentById(id)
        .then(res => {
            if(res?.Data?.[0]?.pat_pin_no) {
                getUserDetails(res?.Data?.[0]?.pat_pin_no, response)
            }  
        })
        .catch(err => {
            console.log(err)
            goHome()
        })
    }

    const getUserDetails = (id, response) => {
        USER_API.getUserFromHH(id)
        .then(res => {
            setPayDetails({...response, ...res?.Data?.[0]})
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            goHome()
        })
    }

    useEffect(() => {
        if(location?.search && location?.search.includes('id')) {
            let base64id = location?.search.split('=')[1] || null
            if(!base64id) goHome()
            if(base64id && Number(atob(base64id)) && atob(base64id)) {
                let id = atob(base64id)
                if(location?.search.includes('eid')) {
                    getEventDetails(id)
                } else {
                    getPaymentDetailsById(id)
                }
            } else {
                goHome()
            }
        } else {
            goHome()
        }
    },[location])

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: [
                        '/assets/css/services-detail.css', 
                        '/assets/css/about-through-the-ages.css', 
                        '/assets/css/accessibility.css',
                        '/assets/css/common.css',
                        '/assets/css/payment-success.css',
                    ]
                }}
                tags={[]}
            />
            <main class="innerpage">
                <section class="section_bg paymentSuccess">
                    <div class="container">
                        {payDetails 
                        ? <div class="patientParagraph">
                            <h3>Thank you for the payment! Here are your details. We have also sent the details on your registered email id.</h3>
                            <span class="titleHeading">Transaction Details</span>
                            {loading 
                                ?  <Loader loadingMessage={`Loading Details...`} />
                                : <>
                                    {detailsType === 'NoDetailsFound'
                                        ? <div className="">No Details Found</div> 
                                        : <>
                                        {detailsType === 'EventDetails' 
                                            ?  <div className="">
                                                {/* EventDetails  */}

                                                { (payDetails?.name || payDetails?.fullname || payDetails?.PatFirstName+' '+payDetails?.PatLastName)  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Name</span>
                                                    <span class="rightText">: {payDetails?.name || payDetails?.fullname || payDetails?.PatFirstName+' '+payDetails?.PatLastName || 'NA'} </span>
                                                </div>}

                                                { payDetails?.event_title  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Event</span>
                                                    <span class="rightText">: {payDetails?.event_title || 'NA'}</span>
                                                </div>}

                                                { payDetails?.age  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Age</span>
                                                    <span class="rightText">: {payDetails?.age || 'NA'}</span>
                                                </div>}

                                                { payDetails?.designation  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Designation</span>
                                                    <span class="rightText">: {payDetails?.designation || 'NA'}</span>
                                                </div>}

                                                { payDetails?.specialization  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Specailization</span>
                                                    <span class="rightText">: {payDetails?.specialization || 'NA'}</span>
                                                </div>}

                                                { payDetails?.medical_registration_number  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Medical Registration Number</span>
                                                    <span class="rightText">: {payDetails?.medical_registration_number || 'NA'}</span>
                                                </div>}

                                                { payDetails?.hospital  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Hospital</span>
                                                    <span class="rightText">: {payDetails?.hospital || 'NA'}</span>
                                                </div>}

                                                { payDetails?.address  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Address</span>
                                                    <span class="rightText">: {payDetails?.address || 'NA'}</span>
                                                </div>}

                                                { payDetails?.city  && 
                                                <div class="tableContent">
                                                    <span class="leftText">City</span>
                                                    <span class="rightText">: {payDetails?.city || 'NA'}</span>
                                                </div>}

                                                { payDetails?.state  && 
                                                <div class="tableContent">
                                                    <span class="leftText">State</span>
                                                    <span class="rightText">: {payDetails?.state || 'NA'}</span>
                                                </div>}

                                                { payDetails?.pincode  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Pincode</span>
                                                    <span class="rightText">: {payDetails?.pincode || 'NA'}</span>
                                                </div>}

                                                { payDetails?.email  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Email ID</span>
                                                    <span class="rightText">: {payDetails?.email || 'NA'}</span>
                                                </div>}

                                                { payDetails?.mobileNo  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Mobile No.</span>
                                                    <span class="rightText">: {payDetails?.mobileNo || 'NA'}</span>
                                                </div>}

                                                {payDetails?.transactionId  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Transaction Referance No.</span>
                                                    <span class="rightText">: {payDetails?.transactionId || payDetails?.transactionRefno || 'NA'}</span>
                                                </div>}

                                                { payDetails?.transactionDate  && 
                                                <div class="tableContent">
                                                    <span class="leftText">Transaction Date & Time</span>
                                                    <span class="rightText">: {payDetails?.transactionDate || 'NA'}</span>
                                                </div>}
                                            </div>
                                            : detailsType === 'CompleteForm' 
                                                ? <div className="">
                                                    {/* Complete form  */}

                                                    { (payDetails?.pinNo || payDetails?.hhno)  && 
                                                    <div class="tableContent">
                                                        <span class="leftText">HH No.</span>
                                                        <span class="rightText">: {payDetails?.pinNo || payDetails?.hhno || 'NA'}</span>
                                                    </div>}

                                                    { payDetails?.name  && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Name</span>
                                                        <span class="rightText">: {payDetails?.name ||  'NA'} </span>
                                                    </div>}
                                                    
                                                    { (payDetails?.gender || payDetails?.Gender)  && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Gender</span>
                                                        <span class="rightText">: {payDetails?.gender || payDetails?.Gender || 'NA'}</span>
                                                    </div>}

                                                    { (payDetails?.age || payDetails?.patage || payDetails?.Age)  && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Age</span>
                                                        <span class="rightText">: {payDetails?.age || payDetails?.patage || payDetails?.Age || 'NA'}</span>
                                                    </div>}

                                                    { payDetails?.ApptNo || payDetails?.apptno && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Appointment No. </span>
                                                        <span class="rightText">: {payDetails?.ApptNo || payDetails?.apptno || 'NA'}</span>
                                                    </div>}

                                                    {payDetails?.orderno &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Order No. </span>
                                                        <span class="rightText">: {payDetails?.orderno || 'NA'}</span>
                                                    </div>}

                                                    {payDetails?.servicename && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Service Name</span>
                                                        <span class="rightText">: {payDetails?.servicename} </span>
                                                    </div>}    

                                                    { payDetails?.consultname  && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Consultant Name</span>
                                                        <span class="rightText">: {payDetails?.consultname || 'NA'}</span>
                                                    </div>}
                                                    
                                                    { payDetails?.aptdatetime !== '' && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Appointment Date & Time</span>
                                                        <span class="rightText">: {payDetails?.aptdatetime || 'NA'}</span>
                                                    </div>}

                                                    { payDetails?.consultcharge && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Consultation Charges</span>
                                                        <span class="rightText">: Rs.{payDetails?.consultcharge}.00/-</span>
                                                    </div>}

                                                    {/* {payDetails?.dropcharge &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Drop Charges</span>
                                                        <span class="rightText">: Rs.{payDetails?.dropcharge}.00/-</span>
                                                    </div>}  */}
                                                    
                                                    {payDetails?.registrationservicecharge && payDetails?.payloadtype !== 'HH' &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Registration Service Charges</span>
                                                        <span class="rightText">: Rs.{payDetails?.registrationservicecharge}.00/-</span>
                                                    </div>}

                                                    { (payDetails?.amountpaid || payDetails?.TotalPayment) &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Amount Paid</span>
                                                        <span class="rightText">: Rs.{payDetails?.amountpaid || payDetails?.TotalPayment}.00/-</span>
                                                    </div>}

                                                    { payDetails?.paystatus && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Payment Status</span>
                                                        <span class="rightText">: {payDetails?.paystatus || 'NA'}</span>
                                                    </div>}

                                                    { payDetails?.voucherno &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Voucher No.</span>
                                                        <span class="rightText">: {payDetails?.voucherno || 'NA'}</span>
                                                    </div>}

                                                    { (payDetails?.transactionRefno || payDetails?.OnlinePayRefNo) && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Transaction Referance No.</span>
                                                        <span class="rightText">: {payDetails?.transactionRefno || payDetails?.OnlinePayRefNo || 'NA'}</span>
                                                    </div>}

                                                    { payDetails?.transactionDate && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Transaction Date & Time</span>
                                                        <span class="rightText">: {payDetails?.transactionDate || 'NA'}</span>
                                                    </div>}
                                                </div>
                                                : <div className="">
                                                {/* OPD, IPD, RSV */}
                                                    {payDetails?.hhno &&
                                                    <div class="tableContent">
                                                        <span class="leftText">HH No.</span>
                                                        <span class="rightText">: {payDetails?.hhno}</span>
                                                    </div>}

                                                    { payDetails?.name &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Name</span>
                                                        <span class="rightText">: {payDetails?.name} </span>
                                                    </div>}

                                                    {payDetails?.reservationNo && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Reservation No.</span>
                                                        <span class="rightText">: {payDetails?.reservationNo}</span>
                                                    </div>}

                                                    {payDetails?.admissionNo && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Admission No.</span>
                                                        <span class="rightText">: {payDetails?.admissionNo}</span>
                                                    </div>}

                                                    { payDetails?.voucherno &&
                                                    <div class="tableContent">
                                                        <span class="leftText">{payDetails?.payloadtype} Deposit No.</span>
                                                        <span class="rightText">: {payDetails?.voucherno}</span>
                                                    </div>}

                                                    { payDetails?.transactionRefno &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Online No.</span>
                                                        <span class="rightText">: {payDetails?.transactionRefno}</span>
                                                    </div>}

                                                    { payDetails?.amountpaid &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Amount Paid</span>
                                                        <span class="rightText">: Rs.{payDetails?.amountpaid}.00/-</span>
                                                    </div>}

                                                    { payDetails?.paystatus && 
                                                    <div class="tableContent">
                                                        <span class="leftText">Payment Status</span>
                                                        <span class="rightText">: {payDetails?.paystatus}</span>
                                                    </div>}

                                                    { payDetails?.transactionId &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Transaction Referance No.</span>
                                                        <span class="rightText">: {payDetails?.transactionId}</span>
                                                    </div>
                                                    }

                                                    { payDetails?.transactionDate &&
                                                    <div class="tableContent">
                                                        <span class="leftText">Transaction Date & Time</span>
                                                        <span class="rightText">: {payDetails?.transactionDate}</span>
                                                    </div>}
                                                </div>
                                            }   
                                        </>
                                    } 
                                </>
                            }
                            <div class="btn_wrap">
                                <button class="btn btn-primary" onClick={goHome}>Explore more</button>
                            </div>
                        </div>
                        : null}
                    </div>
                    <div class="addressFooter">
                        <div class="container">
                            <div class="textContent">
                                <h5>P. D. Hinduja Hospital & Medical Research Center</h5>
                                <p>Veer Savarkar Marg, Mahim, Mumbai-400 016 India.<br />Tel: 24449199 / 24452222 / 24451515.</p>
                            </div>    
                        </div>  
                    </div>  
                </section>  
            </main>
        </Layout>
    )
}

export default Thankyou